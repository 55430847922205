<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col class="text-right">
                <v-btn
                    @click="$router.push({name: 'admin.users.create'})"
                    v-text="'Create'"
                    class="mb-4"
                />
            </v-col>
        </v-row>

        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="users"
            :items-per-page="5"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | formattedDate }}
            </template>
            <template v-slot:item.actions="{ item }">
                <delete-dialog :model="item" type="users" @success="deleted"/>
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import User from '@/models/User';
import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
import DeleteDialog from "@/components/dialogs/DeleteDialog";

export default {
    name: "users-index",
    components: {
        AdminDashboardLayout,
        DeleteDialog
    },
    data: function () {
        return {
            loading: false,
            users: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                { text: 'Name', value: 'name' },
                { text: 'Email', value: 'email' },
                { text: 'Created at', value: 'created_at' },
                { text: 'Actions', value: 'actions' },
            ],
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        async fetch() {
            this.loading = true
            this.users = await User.get()
            this.loading = false
        },
        handleClickRow(user) {
            this.$router.push({ name: 'admin.users.edit', params: { id: user.id } })
        },
        deleted(model) {
            this.users.splice(this.users.findIndex(u => u.id === model.id), 1)
        }
    },
}
</script>

<style scoped>

</style>
